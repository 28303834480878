import { useState } from "react";
import { Backdrop } from "styles/styles.backdrop";
import { getFocus, useAtom, useGet } from "state/jotai";
import { Box, Button, Stack } from "@mui/material";
import { DEPOSITECALCULATIONS, MOVEINCALCULATIONS, STATICS } from "api/api";
import { Icon } from "assets/Icons_";
import styled from "styled-components";
import {
  active_project,
  vis_calculate_benefit,
  vis_deposite,
  vis_movein_price,
} from "state/store.global";
import { housing_benefit } from "state/store.global";
import { gross_income } from "state/store.global";
import {
  Container,
  Inner,
  Result,
  SubText,
  CurrencyInput,
  SideText,
} from "./HousingBenefit.style";
import "./HousingBenefit.css";
import { useHousingBenefit } from "./HousingBenefit.hook";
import { _project } from "state/store.projects";
import { useFetch } from "hooks/fetch/useFetch";
import { useActiveUnit } from "components/Unit";
import { _bootstrap } from "state/store.bootstrap";
import { API }           from 'api/api'

const data = {
  Header1: "Beregn din boligstøtte",
  Text1  : 
    "Indtast din husstands årlige bruttoindtægt (månedsløn før skat x 12), og få lavet en vejledende beregning for din månedlige boligstøtte",
  Header2: "Husstandens årlige bruttoindtægt",
  Header3: "Din vejledende boligstøtte",
  SubText: 
    "Den boligstøtte beregning du kan se her, er vejledende og vi giver ingen garanti i forhold til den endelige boligstøtte du måtte kunne få. Brug eventuelt https://www.boligstoette.dk/ som har en beregner der stilles til rådighed af Udbetaling Danmark.",
};

export const DepositePortal = () => {
  const setDepositePrice = useAtom(vis_deposite)[1];

  const type = useGet(_bootstrap).type

  const mono = type === 'mono'
  const file = mono ? 'main/ProjectData.jsx' : ''
 
  const unit         = useActiveUnit();
  const { pin, pid } = useGet(active_project);
  const images       = unit.getImages();
  
  const MPC  = pid ? DEPOSITECALCULATIONS(pid,unit?.data?.UnitID)  : null
  const MIPC = useFetch({url:mono?MPC:null,file}).D                              /*getting the data for deposite price calculations */
// console.log(MIPC,"mipcc")
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];

  const imageUrl = images[images?.length > 2 ? 3 : 0]?.url;

  const icon = {
    icon : "close6",
    color: "#fff",
    wrap : { position: "absolute", top: 20, right: 20 },
    size : "20",
    click: () => setDepositePrice(false),
  };


  // showing only corret value
  const isInvalidValue = (value) =>
    value === null || value === "0" || value === "0,0" || value === "999999" || value === "999.999";

  return (
    <>
      <Backdrop
        onClick={() => {
          setDepositePrice(false);
        }}
      />
       <Container>
        <StyledImage src = {imageUrl} alt = "Unit Image" />
        <Icon {...icon} />
        <Inner>
          <h2>Hvad er depositum?</h2>
          <div style = {{ display: "flex", paddingBottom: "24px", fontSize: "17px" }}>
            <p>Depositum er udregnet ved husleje x antal måneder i depositum</p>
          </div> 
          <Box>
            <div>
              { !isInvalidValue(MIPC?.rent) && 
                <>
                  <Row>
                  <div>Husleje</div>
                  <div>{MIPC?.rent} kr.</div>
                </Row>
                <Box sx = {{ height: 5 }} />
                </>
              }
              {
                !isInvalidValue(MIPC?.deposit_number_of_month) &&
                <>
                <Line />
                    <Box sx = {{ height: 5 }} />
                    <Row>
                      <div style = {{ display: "flex", gap: "4px" }}>
                        Antal måneder i depositum
                      </div>
                      <div>{MIPC?.deposit_number_of_month} mdr.</div>
                    </Row>
                  </>
              }
            </div>
            <Line />
            <div style = {{ height: 10 }} />
            {!isInvalidValue(MIPC?.deposit) && (
              <Row>
                <div style = {{ fontWeight: "600" }}>Depositum</div>
                <div>
                  <div style = {{ fontWeight: "600" }}>{`${MIPC?.deposit} kr.`}</div>
                </div>
              </Row>
            )}
            <Box sx = {{ height: 5 }} />
            <Line />
          </Box>
          <Stack direction = "row" spacing = "1px" justifyContent = "center"></Stack>
        </Inner>
      </Container>
    </>
  );
};


const StyledImage = styled.img`
  width     : 637px;
  height    : 200px;
  object-fit: cover;
`;

const Row = styled.div`
  padding: 0.4rem 0;
  font-size: 16px;
  font-weight: 400;
  font-family: Arial;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Line = styled.div`
  min-height: 1px;
  max-height: 1px;
  width: 100%;
  background: lightgray;
`;