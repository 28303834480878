import { vis_benefit_extended, vis_compare, vis_deposite, vis_movein_price, vis_prepaidrent }           from 'state/store.global'
import { vis_share }             from 'state/store.global'
import { vis_calculate_benefit } from 'state/store.global'
import { useAtom }               from 'state/jotai'
import { ComparePortal }         from 'components/Compare/Compare.Portal'
import { SharePortal }           from 'components/Share/Share.Portal'
import { BenefitPortal }         from 'components/HousingBenefit/HousingBenefit'
import { HousingBenefitExtended } from 'components/HousingBenefit/HousingBenefitExtended'
import { MoveInPricePortal } from 'components/HousingBenefit/MoveInPrice'
import { DepositePortal } from 'components/HousingBenefit/Deposite'
import { PrePaidRentPricePortal } from 'components/HousingBenefit/PrepaidRent'

export const Portals = () => {

  const [comp, setComp]   = useAtom(vis_compare)
  const [share, setShare] = useAtom(vis_share)
  const [bene, setBene]   = useAtom(vis_calculate_benefit)
  const [beneExtended, setBeneExtended] = useAtom(vis_benefit_extended);
  const [mP, setMP]                     = useAtom(vis_movein_price);
  const [deposite, setDeposite]         = useAtom(vis_deposite);
  const [prePaidRent, setPrePaidRent]   = useAtom(vis_prepaidrent);


  return (
    <>
    { comp  && <ComparePortal close={()=>setComp(false)}/> }
    { share && <SharePortal close={()=>setShare(false)}/>  }
    { bene  && <BenefitPortal close={()=>setBene(false)}/> }
    {mP && <MoveInPricePortal close={() => setMP(false)} />}
    {deposite && <DepositePortal close={() => setDeposite(false)} />}
    {prePaidRent && <PrePaidRentPricePortal close={() => setPrePaidRent(false)} />}
    {
    beneExtended && 
    <HousingBenefitExtended close={() => setBeneExtended(false)} />
    }
    </>
  )

}
